import { render, staticRenderFns } from "./detailMsg.vue?vue&type=template&id=2fb09431&scoped=true&"
import script from "./detailMsg.vue?vue&type=script&lang=js&"
export * from "./detailMsg.vue?vue&type=script&lang=js&"
import style0 from "./detailMsg.vue?vue&type=style&index=0&id=2fb09431&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fb09431",
  null
  
)

export default component.exports