<template>
  <div class="content">
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" size="mini" :model="search">
          <el-form-item label="客户名称">
            <el-input
              v-model="search.customer_name"
              maxlength="30"
              clearable
              class="w140"
              placeholder="请输入"
            />
          </el-form-item>

          <el-form-item label="位置">
            <el-select v-model="search.position_id" clearable class="w140" placeholder="请选择">
              <el-option
                v-for="item in options.position_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="原因">
            <el-select v-model="search.reason_id" clearable class="w150" placeholder="请选择">
              <el-option
                v-for="item in options.reason_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="疤痕类型">
            <el-select v-model="search.type_id" clearable class="w150">
              <el-option
                v-for="item in options.type_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn label="客户名称" prop="customer_name" />
            <ElTableColumn label="性别" prop="sex" />
            <ElTableColumn label="评选位置" prop="position_name" />
            <ElTableColumn label="疤痕原因" prop="reason_name" />
            <ElTableColumn label="疤痕类型" prop="type_name" />
            <ElTableColumn label="护理师" prop="nurse_name" />
            <ElTableColumn label="门店" prop="shop_name" />
            <ElTableColumn label="归属市场" prop="market_name" />
            <ElTableColumn label="评选结果">
              <template slot-scope="{ row }">
                <span v-if="row.selection_results == 0">未申请</span>
                <span v-if="row.selection_results == 1">评选中</span>
                <span v-if="row.selection_results == 2">优秀案例</span>
                <span v-if="row.selection_results == 3">满意案例</span>
                <span v-if="row.selection_results == 4">未通过</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="操作" fixed="right">
              <template slot-scope="{ row }">
                <span class="theme" @click="tapBtn('detail',row.id)">查看</span>
              </template>
            </ElTableColumn>
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import pagination from "@/components/Pagination";
import { goodsList, deleteGoods } from "@/api/goods";
import { ssettingList } from "@/api/client";
import { caseList } from "@/api/case";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  customer_name = ""; // 名称
  position_id = ""; // 位置
  reason_id = ""; // 成因
  type_id = ""; // 类型
}
class Options {
  position_id = [];
  reason_id = [];
  type_id = [];
}
export default {
  name: "GoodsList",
  components: {
    pagination
  },
  props: {
    shopId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      search: new Search(), // 检索条件
      options: new Options(), // 选项
      page: new Page(), // 分页
      list: [] // 数据展示
    };
  },

  mounted() {
    this.getOptions();
    this.getList();
  },
  methods: {
    // 获取选项
    getOptions() {
      ssettingList({ page: 1, limit: 100000 }).then(res => {
        let that = this;
        res.list.forEach(function(item) {
          if (item.category_id == 1) {
            that.options.type_id.push(item);
          } else if (item.category_id == 2) {
            that.options.position_id.push(item);
          } else if (item.category_id == 3) {
            that.options.reason_id.push(item);
          }
        });
      });
    },
    // 获取列表
    getList(option) {
      if (option === "search") this.page = new Page();
      caseList({
        ...this.search,
        ...this.page,
        list_type: 1,
        shop_id: this.shopId
      }).then(res => {
        this.list = res.data.list;
        this.page.total = res.data.dataCount;
      });
    },
    // 点击详情
    tapBtn(type, id) {
      switch (type) {
        case "detail":
          const query = { id: id, shopId: this.shopId };
          this.$router.push({ name: "案例详情", query });
          break;
      }
    },
   
  }
};
</script>

<style lang="scss" scoped>
.bgW {
  background: #fff;
  border-radius: 3px;
  //   padding: 10px;
  margin-bottom: 20px;
}
.el-form-item {
  margin: 5px 20px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 10px 0px 80px;
}
</style>
