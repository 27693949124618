<template>
  <div class="content">
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          <el-form-item label="客户名称">
            <el-input v-model="search.consignee" clearable placeholder="请输入客户名称" />
          </el-form-item>
          <el-form-item label="订单编号">
            <el-input v-model="search.order_no" clearable placeholder="请输入订单编号" />
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="search.status" placeholder="请选择状态">
              <el-option
                v-for="item in options.status"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn label="订单编号" prop="order_no" />
            <ElTableColumn label="是否下推">
              <template slot-scope="{ row }">
                <div v-if="row.king_status == 0">暂未下推</div>
                <div v-if="row.king_status == 1">下推成功</div>
                <div v-if="row.king_status == 2">下推失败</div>
              </template>
            </ElTableColumn>
            <ElTableColumn label="客户名称" prop="consignee" />
            <ElTableColumn label="订单金额" prop="total_amount" />
            <ElTableColumn label="优惠金额" prop="discount_amount" />
            <ElTableColumn label="订单状态" width="100">
              <template slot-scope="{ row }">
                <div v-if="row.status == 0">待支付</div>
                <div v-if="row.status == 1">已支付</div>
                <div v-if="row.status == 2">已下推</div>
                <div v-if="row.status == 3">已评价</div>
                <div v-if="row.status == 4">已作废</div>
              </template>
            </ElTableColumn>
            <ElTableColumn label="创建时间" prop="create_time" />
            <ElTableColumn label="操作" fixed="right">
              <template slot-scope="{ row }">
                <span class="theme" @click="tapDetail('detail',row.id)">查看订单</span>
              </template>
            </ElTableColumn>
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import pagination from "@/components/Pagination";
import { orderList } from "@/api/order";
class Search {
  consignee = "";
  order_no = "";
  status = -1;
}
class Options {
  constructor() {
    this.status = [
      { id: -1, name: "全部" },
      { id: 0, name: "待支付" },
      { id: 1, name: "已审核" },
      { id: 2, name: "已下推" },
      { id: 3, name: "已发货" },
      { id: 4, name: "已收货" },
      { id: 5, name: "已完成" },
      { id: 6, name: "已作废" }
    ];
  }
}
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  name: "StaffList",
  components: {
    pagination
  },
  props: {
    shopId: {
      type: String,
      default: ""
    },
    shopName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      search: new Search(), // 检索条件
      options: new Options(), // 选项
      page: new Page(), // 分页
      list: [] // 数据展示
    };
  },

  mounted() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList(option) {
      if (option == "search") this.page = new Page();
      orderList({ ...this.search, ...this.page, shop_id: this.shopId }).then(
        res => {
          this.list = res.list;
          this.page.total = res.dataCount;
        }
      );
    },
    // 点击详情
    tapDetail(type, id) {
      switch (type) {
        case "detail":
          this.$router.push("./sellDetail?id=" + id + "&shopId=" + this.shopId);
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  height: calc(100% - 56px);
  overflow-y: auto;
}
.bgW {
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 20px;
}

.el-form-item {
  margin: 5px 40px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 0px 10px 80px;
}
</style>
