<template>
  <div>
    <div class="conMsg">
      <div class="top">
        <div class="title">
          <i class="el-icon-collection-tag" />基本资料
        </div>
        <div>
          <el-button class="mb10" type="primary" size="mini" round @click="openDialog('编辑资料')">编辑资料</el-button>
       
        </div>
      </div>
      <el-descriptions class="margin-top" :column="3"  border>
    
    <el-descriptions-item>
      <template slot="label">店铺序号</template>
      {{detail.deptno}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">店铺名称</template>
      {{detail.shopName}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">部门序号</template>
      {{detail.deptno}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">归属市场</template>
      {{detail.marketName}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">大区归属</template>
      {{detail.regionName}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">门店类型</template>
      {{detail.typeName}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">是否特殊门诊</template>
      {{detail.isSign != 1?'是':'否'}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">门店金蝶编码</template>
      {{detail.keycode?detail.keycode:'暂无'}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">门店状态</template>
      <span v-if="detail.status ==1">运营中</span>
      <span v-if="detail.status ==2">临时关闭</span>
      <span v-if="detail.status ==3">永久关闭</span>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">开店时间</template>
      {{detail.openAt}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">仓库</template>
      {{detail.storeName}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">营业时间</template>
      {{detail.startTime}} - {{detail.endTime}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">门店电话</template>
      {{detail.shopPhone}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">门店地址</template>
      {{detail.provinceName}}{{detail.cityName}}{{detail.countryName}}{{detail.address}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">面积</template>
      <span v-if="detail.area">{{detail.area}}m²</span>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">月租</template>
      <span v-if="detail.monthlyFee">{{detail.monthlyFee}}元</span>
    </el-descriptions-item>
    <el-descriptions-item :span="2">
      <template slot="label">简介</template>
      {{detail.introduce}}
    </el-descriptions-item>
     <el-descriptions-item>
      <template slot="label">床位</template>
      {{detail.bedNum}}
    </el-descriptions-item>
    <el-descriptions-item :span="2">
      <template slot="label">备注</template>
      {{detail.remark}}
    </el-descriptions-item>           
    
  </el-descriptions>
    </div>

    <div class="conMsg">
      <div class="top">
        <div class="title">
          <i class="el-icon-collection-tag" />上班时间
        </div>
      </div>
        <el-descriptions  :column="2" border>
          <el-descriptions-item>
            <template slot="label">上午班</template>
            {{detail.amWork}}
          </el-descriptions-item>     
          <el-descriptions-item :span="1">
            <template slot="label">下午班</template>
            {{detail.pmWork}}
          </el-descriptions-item>
        </el-descriptions>
      
    </div>


    <div class="conMsg">
      <div class="top">
        <div class="title">
          <i class="el-icon-collection-tag" />店长信息
        </div>
        <el-button
          v-if="userInfo.type != 3"
          class="mb10"
          type="primary"
          size="mini"
          round
          @click="openDialog('编辑店长资料')"
        >编辑店长资料</el-button>
      </div>
        <el-descriptions direction="vertical" :column="4" border>
          <el-descriptions-item label="店长姓名">{{detail.shopOwner}}</el-descriptions-item>
          <el-descriptions-item label="性别">{{detail.sex==1?'男':'女'}}</el-descriptions-item>
          <el-descriptions-item label="联系电话" >{{detail.shopMobile}}</el-descriptions-item>
          <el-descriptions-item label="最后登录时间" >{{detail.lastLoginAt}}</el-descriptions-item>
        </el-descriptions>
      
    </div>
    <div class="conMsg">
      <div class="top">
        <div class="title">
          <i class="el-icon-collection-tag" />统计信息
        </div>
      </div>
      <el-descriptions direction="vertical" :column="4" border>
          <el-descriptions-item label="员工数量">{{detail.personCount}}</el-descriptions-item>
          <el-descriptions-item label="客户数量">{{detail.customerCount}}</el-descriptions-item>
          <el-descriptions-item label="销售数量" >{{detail.saleNum}}</el-descriptions-item>
          <el-descriptions-item label="服务数量" >{{detail.serviceCount}}</el-descriptions-item>
          <el-descriptions-item label="未处理预警" >{{detail.noWarning}}</el-descriptions-item>
          <el-descriptions-item label="未处理追责" >{{detail.noAccountability}}</el-descriptions-item>
          <el-descriptions-item label="回访数量" >{{detail.visitNum}}</el-descriptions-item>
          <el-descriptions-item label="退货次数" >{{detail.returnTimes}}</el-descriptions-item>
        </el-descriptions>
     
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { shopDetail } from "@/api/shop";
export default {
  name: "DetailMsg",
  props: {
    shopId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      detail: {}
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {
    this.getDetail(this.shopId);
  },
  methods: {
    // 获取详情
    getDetail(shopId) {
      shopDetail({ shopId: shopId }).then(res => {
        this.detail = res;
        console.log(this.detail);
      });
    },
    // 操作
    openDialog(type, row) {
      switch (type) {
        case "编辑资料":
          this.$router.push({
              path: './compileShop',
              query: this.detail
          });
          break;
        case "编辑店长资料":
          this.$router.push({
              path: './leadData',
              query: this.detail
          });
          break;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.conMsg {
  margin-bottom: 30px;
  .top {
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: bold;
      font-size: 14px;
      line-height: 34px;
      color: #666;
    }
  }
}
::v-deep .el-descriptions-item__label.is-bordered-label{
  background: #f9fafc;
}
</style>
