import { render, staticRenderFns } from "./sellRecord.vue?vue&type=template&id=0df6d95f&scoped=true&"
import script from "./sellRecord.vue?vue&type=script&lang=js&"
export * from "./sellRecord.vue?vue&type=script&lang=js&"
import style0 from "./sellRecord.vue?vue&type=style&index=0&id=0df6d95f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0df6d95f",
  null
  
)

export default component.exports