<template>
  <div >
    <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick" >
      <el-tab-pane label="店铺详情" name="first">
        <detailMsg v-if="activeName=='first' && shopId"  :shop-id ="shopId"/>
      </el-tab-pane>
      <el-tab-pane label="销售记录" name="second">
        <sellRecord v-if="activeName=='second' && shopId"  :shop-id ="shopId" :shop-name = "shopName" />
      </el-tab-pane>
      <!-- <el-tab-pane label="服务记录" name="third">
        <serveRecord v-if="activeName=='third' && shopId"  :shop-id ="shopId"/>
      </el-tab-pane> -->
      <el-tab-pane label="案例列表" name="fourth">
        <caseList v-if="activeName=='fourth' && shopId"  :shop-id ="shopId"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import detailMsg from './components/detailMsg.vue'
import sellRecord from './components/sellRecord.vue'
// import serveRecord from './components/serveRecord.vue'
import caseList from './components/caseList.vue'
export default {
  name: 'ShopDetail',
  components: {
    detailMsg,
    sellRecord,
    // serveRecord,
    caseList
  },
  data () {
    return {
        shopId:'',
        shopName:'',
        activeName:'first'
    }
  },

  mounted () {
    this.shopId = this.$route.query.id
    this.shopName = this.$route.query.name
    this.activeName = this.$route.query.activeName
    
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>

</style>
